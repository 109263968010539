import { useNavigate } from "react-router-dom";

const SignUp = () => {
    const navigate = useNavigate();

    navigate('/');
    return (
        <div />
    );
};

export default SignUp;
