import { GENERIC_ERROR } from "../reducer/notification";

 
const failure = (dispatch: any, type: string, input: any, onFailure?: (payload: any) => any) => (payload: any) => {
    dispatch({
        type,
        ...input,
        data: payload?.data,
    });

    dispatch({
        type: GENERIC_ERROR,
        message: payload?.response?.data?.length ? payload.response.data : payload?.response?.statusText ?? 'Something went wrong!',
    });

    if (onFailure) onFailure(payload);
};

export default failure;
