 
 
import { get, post, postMulti } from "../http/request";
import execute from "../http/execute";
import success from "../http/success";
import failure from "../http/failure";
import { Configuration, defaultConfig, fillConfig } from "../../utils/config";

export const GET_ME_REQUEST = 'auth/GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'auth/GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'auth/GET_ME_FAILURE';

export const GET_ME_CODE_REQUEST = 'auth/GET_ME_CODE_REQUEST';
export const GET_ME_CODE_SUCCESS = 'auth/GET_ME_CODE_SUCCESS';
export const GET_ME_CODE_FAILURE = 'auth/GET_ME_CODE_FAILURE';

export const ACCEPT_TERMS_REQUEST = 'auth/ACCEPT_TERMS_REQUEST';
export const ACCEPT_TERMS_SUCCESS = 'auth/ACCEPT_TERMS_SUCCESS';
export const ACCEPT_TERMS_FAILURE = 'auth/ACCEPT_TERMS_FAILURE';

export const UPLOAD_IMAGE_SUCCESS = 'auth/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'auth/UPLOAD_IMAGE_FAILURE';
export const UPLOAD_IMAGE_REQUEST = 'auth/UPLOAD_IMAGE_REQUEST';

export const SET_IS_DARK = 'auth/SET_IS_DARK';

export const SET_LANGUAGE = 'auth/SET_LANGUAGE';

export interface Me {
    firstName: string;
    lastName: string;
    csdeLink: string;
    email: string;
    institutionName: string;
    address: {
        addressLineOne?: string;
        addressLineTwo?: string;
        city?: string;
        state?: string;
        zipCode?: string;
    };
    spouse: {
        firstName?: string;
        lastName?: string;
        email?: string;
    };
    advisor: {
        name: string;
        email: string;
    };
    institution: {
        name: string;
        domain: string;
    };
    imageUrl?: string;
    tpInstitution: boolean;
    riaTncAccepted: Date | string;
    ria: boolean;
    tcId: boolean;
}

const initialState: {
    me: Me | object;
    meFailed: boolean;
    isDark: boolean;
    lng: 'en' | 'es';
    configuration: Configuration;
} = {
    me: {},
    meFailed: false,
    isDark: false,
    lng: 'en',
    configuration: defaultConfig,
};

function reducer(state = initialState, action: any): typeof initialState {
    switch (action.type) {
        case GET_ME_FAILURE:
            return {
                ...state,
                meFailed: true,
            };

        case ACCEPT_TERMS_SUCCESS:
        case GET_ME_SUCCESS:
            return {
                ...state,
                me: action.data,
                meFailed: false,
            };
        case GET_ME_CODE_SUCCESS:
            const newState = {
                ...state,
                me: action.data.me,
                meFailed: false,
            };
            if (action.data.configuration) {
                newState.configuration = fillConfig(action.data.configuration);
            }
            return newState;

        case UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                me: {
                    ...state.me,
                    imageUrl: action.data.imageUrl,
                },
            };

        case SET_IS_DARK:
            return {
                ...state,
                isDark: action.isDark,
            };

        case SET_LANGUAGE:
            return {
                ...state,
                lng: action.language,
            };

        default:
            return state;
    }
}

export const getMe = (payload?: any) => (dispatch: any) => {
    execute(get, '/me')
        .then(success(dispatch, GET_ME_SUCCESS, payload))
        .catch(failure(dispatch, GET_ME_FAILURE, payload));

    dispatch({
        type: GET_ME_REQUEST,
        payload,
    });
};

export const getMeByCode = (payload: { code: string; email: string; }) => (dispatch: any) => {
    execute(get, `/ria/me?code=${payload.code}&email=${encodeURI(payload.email.replace(/\+/g, '%2B'))}`)
        .then(success(dispatch, GET_ME_CODE_SUCCESS, payload))
        .catch(failure(dispatch, GET_ME_CODE_FAILURE, payload));

    dispatch({
        type: GET_ME_CODE_REQUEST,
        payload,
    });
};

export const acceptTerms = (payload?: any) => (dispatch: any) => {
    execute(post, '/accept', payload)
        .then(success(dispatch, ACCEPT_TERMS_SUCCESS, payload))
        .catch(failure(dispatch, ACCEPT_TERMS_FAILURE, payload));

    dispatch({
        type: ACCEPT_TERMS_REQUEST,
        payload,
    });
};

export const uploadImage = (payload?: any) => (dispatch: any) => {
    execute(postMulti, '/me/image', payload.data)
        .then(success(dispatch, UPLOAD_IMAGE_SUCCESS, payload))
        .catch(failure(dispatch, UPLOAD_IMAGE_FAILURE, payload));

    dispatch({
        type: UPLOAD_IMAGE_REQUEST,
        payload,
    });
};

export const setIsDark = (isDark: boolean) => (dispatch: any) => {
    dispatch({
        type: SET_IS_DARK,
        isDark,
    });
};

export default reducer;
