 
const success = (dispatch: any, type: string, input: any, onSuccess?: (payload: any) => any) => (payload: any) => {
    dispatch({
        type,
        ...input,
        data: payload.data,
    });

    if (onSuccess) onSuccess(payload);
    if (payload.onSuccess) payload.onSuccess(payload);
};

export default success;
