 
export type StatusState = {
    loading: any;
    success: any;
    failure: any;
};

const initialState: StatusState = {
    loading: {},
    success: {},
    failure: {},
};

const reducer = (state = initialState, { type: actionType }: { type: string }) => {
    const matches = /(\w+)\/(\w+)_(REQUEST|SUCCESS|FAILURE)/.exec(actionType);

    if (!matches) return state;

    const [, reducerName, actionName, requestState] = matches;
    const namespace = `${reducerName}/${actionName}`;

    return {
        ...state,
        loading: {
            ...state.loading,
            [namespace]: requestState === 'REQUEST',
        },
        success: {
            ...state.success,
            [namespace]: requestState === 'SUCCESS',
        },
        failure: {
            ...state.failure,
            [namespace]: requestState === 'FAILURE',
        },
    };
};

export default reducer;
