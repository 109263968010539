import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';

import './index.css';
import "./App.css";
import { msalConfig } from './authConfig';
import App from './App';
import { jwtInterceptor } from './jwtInterceptor';
import store from './configureStore';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: any) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS
      || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      || event.eventType === EventType.SSO_SILENT_SUCCESS)
    && event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});
jwtInterceptor(msalInstance);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
);
