import { Image } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../configureStore";
import { Configuration } from "../../utils/config";
import Text from "../atoms/Text";

interface RIAHeaderParams {
    envCode?: string;
}

const RIAHeader = ({
    envCode,
}: RIAHeaderParams) => {
    const config = useSelector<RootState, Configuration>(({ auth }) => auth.configuration);

    return config.header.logo ? (
        <Image
            src={config.header.logo.src}
            height={config.header.logo.height}
            width={config.header.logo.width}
            className="px-4 py-4"
            preview={false}
        />
    ) : (
        <div
            className={`backgroundImage${config.header.noImageTint ? '' : ' background-tint'}`}
            style={{
                backgroundImage: config?.header?.image ? `url(${config.header.image})` : `url('https://assetssttaxprep${envCode}.blob.core.windows.net/hsw-tp-assets-${envCode}/ria-background.png')`,
            }}
        >
            <Text tag="h2" customText={config.header.title} />
            <Text tag="h2" customText={config.header.subtitle} />
        </div>
    );
};

export default RIAHeader;
