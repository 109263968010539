 
export const WIPE_NOTIFICATION = 'notification/WIPE_NOTIFICATION';
export const GENERIC_ERROR = 'notification/GENERIC_ERROR';

export type ToastNotification = {
    level: 'success' | 'error' | 'info' | null;
    title: string | null;
    message: string | null;
}

const initialState: ToastNotification = {
    level: null,
    title: null,
    message: null,
};

function reducer(state = initialState, action: any) {
    switch (action.type) {
        case WIPE_NOTIFICATION:
            return {
                ...state,
                level: null,
                title: null,
                message: null,
            };

        case GENERIC_ERROR:
            return {
                ...state,
                level: 'error',
                title: action.title ?? 'Error',
                message: action.message,
            };

        default:
            return state;
    }
}

export default reducer;
