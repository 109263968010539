import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadOverlay from "../atoms/LoadOverlay";

const endpoint = (process.env.REACT_APP_ENDPOINT || 'http://localhost:7071/api').replace('/client-api', '/user');

const Unsubscribe = () => {
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string>();
    const [params] = useSearchParams();

    const unsub = (code: string, email: string) => {
        fetch(`${endpoint}/unsubscribe`, {
            method: 'post',
            body: JSON.stringify({
                code,
                emailAddress: email,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUB_KEY ?? '',
            },
        }).then((res) => {
            setSuccess(res.ok);
            if (!res.ok) setError(`Something went wrong trying to unsubscribe your email: ${res.statusText}`);
        }).catch(() => {
            setError("Something went wrong trying to unsubscribe your email.");
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        const code = params.get('code');
        const email = params.get('email')?.replace(' ', '+') || null;
        if (code && email) unsub(code, email);
    }, [params]);

    return (
        <div>
            {
                loading
                    ? <LoadOverlay />
                    : (
                        <div className="flex-center-center" style={{ height: '100vh' }}>
                            {
                                error && <p className="danger--text" style={{ fontSize: 24 }}>{error}</p>
                            }
                            {
                                success && <p style={{ fontSize: 24 }}>You have successfully been unsubscribed</p>
                            }
                        </div>
                    )
            }
        </div>
    );
};

export default Unsubscribe;
